import React from 'react'
import Footer from './Footer'
// import classes from './StoriesFooter.module.css'

// const StoryCard = (props) => {
//   return (
//     <div className={classes.OneStoryCard}>
//       <img className={classes.storyCard_img} width="440px" height="200px" src={props.img} alt="none" />
//       <p className={classes.storyCard_text}>{props.text}</p>
//     </div>
//   )
// }


const StoriesFooter = () => {
  return (
    <>
      <Footer />
    </>
  )
}

export default StoriesFooter
