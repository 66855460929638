import React from 'react'
import classes from './Footer.module.css'

const Footer = () => {
  return (
    <>
      <div className={classes.FooterBanner__Wrapper}>
        <div>
          <h2 className={classes.FooterBanner_h2}>About us </h2>
          <p>OpenCode Technologies,<br />
            2 Alpha Complex 10 Jln 1/47B<br />
            Kuala Lumpur <br />
            Malaysia
          </p>
          <p>Email : contactus@maxchat.me</p>
          {/* <p>Terms of service:&nbsp;&nbsp; <a href='/terms_en'>English</a>&nbsp;&nbsp;<a href='/terms_ar'>Arabic</a></p> */}
        </div>
        <div className={classes.FooterBanner_img}></div>
        {/* <button className={classes.FooterBanner_edgeBtn}><br /><br /></button> */}
      </div>
      {/* <div className={classes.Footer_end}>
        <img src={logo} alt='none' width="120px" height="55px" />
        <a href="download_thing">
          <button className={classes.footer_btn}>Download Max </button>
        </a> 
      </div> */}
    </>
  )
}

export default Footer
