import React from 'react'
import logoMax from '../../../asses/max_logo.png'
import classes from "./SlideContent.module.css"

const SlideContent = (props) => {
  return (
    <div className={classes.slide_content}>
      <img className={classes.slide_logo_img} src={logoMax} alt="none" width="100px" height="100px" /><br />
      <h1 className={classes.slide_title}>{props.slide_title}</h1>
      <p className={classes.slide_p}>{props.slide_p}</p><br />
      <a href="https://download.maxmessenger.net:22211/share/maxmessenger.apk">
        <button className={classes.slide_btn}>Download &nbsp;<span className={classes.slide_arrow}>→</span></button>
      </a>
    </div>
  )
}

export default SlideContent
