import React, { useRef, useState } from 'react'
import logoMax from '../../../asses/max_logo.png'
import FadeInUp from '../../UI/FadeInUp'
import AdvCard from '../other_parts/AdvCard'
import classes from "./Advantages.module.css"

import lock_svg from "../../../asses/adv_svg/lock.svg"
import messages_svg from "../../../asses/adv_svg/messages.svg"
import group_svg from "../../../asses/adv_svg/group.svg"
import styling_svg from "../../../asses/adv_svg/styling.svg"
import control_svg from "../../../asses/adv_svg/control.svg"
import call_svg from "../../../asses/adv_svg/call.svg"


const Advantages = () => {
  const [bigPoint, setBigPoint] = useState("1")
  const [scrollLeft, setScrollLeft] = useState(0);
  const myDivRef = useRef(null);

  const scrollToPosition = (pointNumber) => {
    if (myDivRef.current) {
      myDivRef.current.scrollToPosition = 1000;
      setScrollLeft(1000);
      console.log(scrollLeft);
      setBigPoint(pointNumber)
    }
  };

  // const Adv_card_Data = {
  //   1: (
  //     <FadeInUp>
  //       <AdvCard title="Active & engaged audience" p="Global and diverse users that are highly connected and attentive." icon="https://env-stage-hosting.s3.eu-central-1.amazonaws.com/people_1_8871994b34.svg" />
  //     </FadeInUp>
  //   )
  // }

  return (
    <section className={classes.Advantages__Section}>
      <div ref={myDivRef} className={classes.AdvCard_container}>
        <div className={classes.AdvCard}>
          <FadeInUp>
            <div>
              <div>The</div>
              <h3 className={classes.advantage_title}>Max Features</h3>
            </div>
          </FadeInUp>
        </div>
        <section className={classes.Advantages}>
          <div id="p1" className={classes.AdvCard1}>
            <FadeInUp>
              <AdvCard title="Instant Messaging" p="Enable users to send and receive messages in real time" icon={messages_svg} />
            </FadeInUp>
          </div>
          <div id="p2" className={classes.AdvCard2}>
            <FadeInUp>
              <AdvCard title="Voice and Video Calls" p="Allow users to make high-quality voice and video calls" icon={call_svg} />
            </FadeInUp>
          </div>
          <div id="p3" className={classes.AdvCard3}>
            <FadeInUp>
              <AdvCard title="Private & Public Group Chat" p="create and manage private and public group chats" icon={group_svg} />
            </FadeInUp>
          </div>
          <div id="p4" className={classes.AdvCard4}>
            <FadeInUp>
              <AdvCard title="Full & Secure Encryption" p="One-on-one encryption for messages and group chat." icon={lock_svg} />
            </FadeInUp>
          </div>
          <div id="p5" className={classes.AdvCard5}>
            <FadeInUp>
              <AdvCard title="Control your Status" p="Control when others can see your online status." icon={control_svg} />
            </FadeInUp>
          </div>
          <div id="p6" className={classes.AdvCard6}>
            <FadeInUp>
              <AdvCard title="Customizable Profiles" p="The ability to create and manage personalized profiles." icon={styling_svg} />
            </FadeInUp>
          </div>
          {/* <button className={classes.leftArrow_btn}>←</button>
          <button className={classes.rightArrow_btn}>→</button> */}
        </section>
        <div className={classes.AdvCard_btn} style={{ textAlign: "center" }}>
          <br />
          <a onClick={() => scrollToPosition("1")} className={bigPoint === "1" ? classes.pagination_scroll_big : classes.pagination_scroll} href="#p1">.</a>
          <a onClick={() => scrollToPosition("2")} className={bigPoint === "2" ? classes.pagination_scroll_big : classes.pagination_scroll} href="#p2">.</a>
          <a onClick={() => scrollToPosition("3")} className={bigPoint === "3" ? classes.pagination_scroll_big : classes.pagination_scroll} href="#p3">.</a>
          <a onClick={() => scrollToPosition("4")} className={bigPoint === "4" ? classes.pagination_scroll_big : classes.pagination_scroll} href="#p4">.</a>
          <a onClick={() => scrollToPosition("5")} className={bigPoint === "5" ? classes.pagination_scroll_big : classes.pagination_scroll} href="#p5">.</a>
          <a onClick={() => scrollToPosition("6")} className={bigPoint === "6" ? classes.pagination_scroll_big : classes.pagination_scroll} href="#p6">.</a>
          <br />
          <img src={logoMax} alt="none" width="120px" height="120px" />
          <p style={{ fontSize: "20px", margin: "0px 0px", marginBottom: "20px" }}>Don't have Max Messenger yet?</p><br />
          <a href="https://download.maxmessenger.net:22211/share/maxmessenger.apk" >
            <button className={classes.Adv_btn}>Download <span className={classes.Adv_arrow}>→</span></button>
          </a>
        </div>
      </div>
    </section >
  )
}

export default Advantages
