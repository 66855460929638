import React from 'react'
import classes from './Terms.module.css'
import Footer from '../../components/Business/other_parts/Footer'

const Terms = () => {
  return (
    <>
      <div className={classes.terms_of_service_container}>
        <div id="Terms_&_Policies" className={classes.terms_header}>
          <h2>Privacy Policy</h2>
          <p>Max Messenger utilizes state-of-the-art security with military-grade 256-bit encryption to provide private messaging, Internet calling, and other services to users worldwide. You agree to our Terms of Service by installing or using Max Messenger app.</p>
        </div>
        <ul className={classes.terms_container}>
          <li id="Account_Registration" className={classes.term_item}>
            <h3>Account Registration</h3>
            <p>To create an account you must register for our Services using your phone number. You agree to receive text messages and phone calls (from us or our third-party providers) with verification codes to register for our Services.</p>
          </li>
          <li id="Privacy_of_user_data" className={classes.term_item}>
            <h3>Privacy of user data</h3>
            <p>Max Messenger does not sell, rent, or monetize your personal data or content in any way. We prioritize the security of your information and utilize end-to-end encryption to ensure that your messages and content are protected. Your privacy is our utmost priority.</p>
          </li>
          <li id="Contact_List" className={classes.term_item}>
            <h3>Contact List </h3>
            <p>In regular intervals Max Messenger needs to read your address book as stored on your phone in order to know who uses Max Messenger from your friends who are in your contacts.</p>
          </li>
          <li id="Software" className={classes.term_item}>
            <h3>Software</h3>
            <p>In order to enable new features and enhanced functionality, you consent to downloading and installing updates to our Services.</p>
          </li>
          <li id="Fees_and_Taxes" className={classes.term_item}>
            <h3>Fees and Taxes</h3>
            <p>Max Messenger is free messaging and internet calls services, You are responsible for data and mobile carrier fees and taxes associated with the devices on which you use our Services.</p>
          </li>
          <li id="Legal_and_Acceptable_Use" className={classes.term_item}>
            <h3>Legal and Acceptable Use </h3>
            <div>You agree to use Max Messenger only for legal, authorized, and acceptable purposes. You will not use (or assist others in using) this app in ways that: <ul style={{ listStyleType: "disc" }}><li> violate or infringe the rights of Max Messenger, our users, or others, including privacy, publicity, intellectual property, or other proprietary rights </li><li> involve sending illegal or impermissible communications such as bulk messaging, auto-messaging, and auto-dialing.</li></ul></div>
          </li>
          <li id="Account_Deletion_Option" className={classes.term_item}>
            <h3>Account Deletion Option </h3>
            <p>Users can delete their account and associated data by accessing the account details, selecting "Delete account" from the overflow menu in the "Manage Account" section. This action permanently terminates the account and removes all associated information.</p>
          </li>
          <li id="Harm_to_Max_Messenger" className={classes.term_item}>
            <h3>Harm to Max Messenger </h3>
            <div>You must not (or assist others to) access, use, modify, distribute, transfer, or exploit our Services in unauthorized manners, or in ways that harm Max Messenger, our Services, or systems. For example you must not <ul style={{ listStyleType: "disc" }}><li> gain or try to gain unauthorized access to our Services or systems; </li><li> disrupt the integrity or performance of our Services; </li><li> create accounts for our Services through unauthorized or automated means; </li><li> collect information about our users in any unauthorized manner. or </li><li> sell, rent, or charge for our Services.</li></ul></div>
          </li>
          <li id="No_Access_to_Emergency_Services" className={classes.term_item}>
            <h3>No Access to Emergency Services </h3>
            <p>Max Messenger do not provide access to emergency service providers like the police, fire department, hospitals, or other public safety organizations. Make sure you can contact emergency service providers through a mobile, fixed-line telephone, or other service.</p>
          </li>
          <li id="Third-party_services" className={classes.term_item}>
            <h3>Third-party services </h3>
            <p>Max Messenger may allow you to access, use, or interact with third-party websites, apps, content, and other products and services. When you use third-party services, their terms and privacy policies govern your use of those services.</p>
          </li>
          <li id="Privacy_Policy_Updates" className={classes.term_item}>
            <h3>Privacy Policy Updates </h3>
            <p>We may change our Privacy Policy from time to time to reflect our current practices and services. You may check this page regularly for any modifications. By using our Services, you agree to our updated Privacy Policy.</p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  )
}

export default Terms
