import React, { useState, useEffect, useRef } from 'react';
import classes from './Business.module.css';
import Counter from './parts/Counter';
import VideoAdvantages from './parts/VideoAdvantages';
import StoriesFooter from './other_parts/StoriesFooter';
import SlideContent from './parts/SlideContent';

const slides = [
  { id: '1', title: 'MAX Messenger', text: 'MAX Messenger is a new communication application that enables you to place FREE calls and send FREE messages anytime and anywhere, 24/7.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/1_436f16d0b1.png" },
  { id: '2', title: 'Instant Texts, Voice & Video Calls', text: 'Enjoy free text messaging, as well as international voice and video calls with your friends, all for free!', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/10_Tips_for_an_Effective_Viber_Messaging_Campaign_9bba902510.png" },
  { id: '3', title: 'Personalized profiles', text: 'Customize your conversations and express yourself with unique themes and fonts to chat in style.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/Maximize_The_Value_of_Chatbots_fa0c910c35.png" },
  { id: '4', title: 'Privacy First', text: 'We take your privacy very seriously. Our app uses one-on-one encryption and secure messaging to keep your information safe and protect your privacy.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/Superapp_for_Marketers_Part_1_What_is_a_Superapp_900530d63d.png" },
  { id: '5', title: 'Multi-Platform', text: 'Our app currently supports Android devices and will soon be available on iOS, allowing for seamless messaging between the two platforms. Stay connected anytime and anywhere with our app.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/Milestone_Release_Meet_Commercial_Account_and_Business_Inbox_99fbc24515.png" },
  { id: '6', title: 'Backup your Messaging', text: "Never lose your messages again! With our apps backup feature, you can save your chat history and easily restore it if you accidentally delete the app. Keep your conversations safe and secure with our convenient backup feature.", img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/One_to_One_Marketing_in_the_Age_of_Privacy_61884a24cd.png" },

  // { id: '1', title: 'Stay Connected Anytime, Anywhere:', text: 'On-the-go messaging. Voice and video calls. Share memories.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/1_436f16d0b1.png" },
  // { id: '2', title: 'Efficient Communication: Boost Your Productivity', text: 'Streamline communication. File sharing. Group chats. Task management.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/10_Tips_for_an_Effective_Viber_Messaging_Campaign_9bba902510.png" },
  // { id: '3', title: 'User-Friendly and Intuitive: Easy Communication', text: 'Intuitive interface. Easy to use. Seamless communication at your fingertips.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/Maximize_The_Value_of_Chatbots_fa0c910c35.png" },
  // { id: '4', title: 'Chat in Style: Customize Your Conversations', text: 'Express yourself. Unique themes and fonts. Chat in style.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/Superapp_for_Marketers_Part_1_What_is_a_Superapp_900530d63d.png" },
  // { id: '5', title: 'Privacy First: Your Security is top priority', text: 'One-on-one Encryption. Protect your privacy. Secure messaging.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/Milestone_Release_Meet_Commercial_Account_and_Business_Inbox_99fbc24515.png" },
  // { id: '6', title: 'Your Chat Hub: Manage All Your Conversations', text: 'All-in-one messaging. Manage conversations. Stay organized.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/One_to_One_Marketing_in_the_Age_of_Privacy_61884a24cd.png" },
  // { id: '7', title: 'Connect Everywhere: Multi-Platform App for Android and iOS', text: 'Seamless messaging on Android and iOS. Stay connected across devices. Chat anytime, anywhere.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/Understanding_Conversational_Commerce_958b1f496a.png" },
  // { id: '8', title: 'Connect with Confidence: Robust Security Features', text: 'Advanced security features. Protect your data. Chat with confidence.', img: "https://env-stage-hosting.s3.eu-central-1.amazonaws.com/Superapp_for_Marketers_Part_1_What_is_a_Superapp_900530d63d.png" },
];

const Business = () => {
  const [selectedSlide, setSelectedSlide] = useState(slides[0]);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      setSelectedSlide((prevSlide) => {
        const currentIndex = slides.findIndex((slide) => slide.id === prevSlide.id);
        const nextIndex = (currentIndex + 1) % slides.length;
        return slides[nextIndex];
      });
    }, 8000);

    return () => clearInterval(intervalIdRef.current);
  }, [setSelectedSlide]);

  useEffect(() => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(() => {
      setSelectedSlide((prevSlide) => {
        const currentIndex = slides.findIndex((slide) => slide.id === prevSlide.id);
        const nextIndex = (currentIndex + 1) % slides.length;
        return slides[nextIndex];
      });
    }, 8000);

    return () => clearInterval(intervalIdRef.current);
  }, [selectedSlide]);

  const handleSlideClick = (slide) => {
    setSelectedSlide(slide);
  };


  return (
    <div>
      <div className={classes.container_switcher_slide}>
        <ul className={classes.switcher_tapContainer}>
          {slides.map((slide) => (
            <li
              key={slide.id}
              className={`${classes.switcher_tap} ${selectedSlide.id === slide.id ? classes.selectedStyle : {}}`}
              onClick={() => handleSlideClick(slide)}
            >
              <h4 className={classes.switcher_tapTitle}>{slide.title}</h4>
            </li>
          ))}
        </ul>
        <SlideContent slide_title={selectedSlide.title} slide_p={selectedSlide.text} slide_img={selectedSlide.img} />
      </div>
      <Counter />

      <VideoAdvantages className="fadeInUp" />
      <br /><br /><br />
      <br /><br /><br />
      <StoriesFooter />
    </div >
  );
};

export default Business;