import React from 'react'
import classes from './TermsAr.module.css'
import Footer from '../../components/Business/other_parts/Footer'

const TermsAr = () => {
  return (
    <>
      <div className={classes.terms_of_service_container}>
        <div id="Terms_&_Policies" className={classes.terms_header}>
          <h2>شروط خدمة ماكس مسنجر للتراسل الفوري</h2>
          <p>يستخدم تطبيق ماكس مسنجر للتراسل الفوري أحدث وسائل الأمان وحماية الخصوصية مع تشفير 256 بت لتوفير حماية متكاملة لرسائلك الخاصة والمكالمات عبر الإنترنت والخدمات الأخرى لمستخدمي ماكس مسنجر في جميع أنحاء العالم. أنت توافق على شروط الخدمة الخاصة بنا عن طريق تثبيت أو استخدام تطبيق ماكس مسنجر.</p>
        </div>
        <ul className={classes.terms_container}>
          <li id="Account_Registration" className={classes.term_item}>
            <h3>انشأ حسابك في ماكس مسنجر</h3>
            <p>لإنشاء حساب ، يجب عليك التسجيل في خدماتنا باستخدام رقم هاتفك. وبذلك أنت توافق على تلقي رسائل نصية ومكالمات هاتفية (منا أو من مزودي الطرف الثالث المتعاملين مع خدماتنا) مع رموز التحقق للتسجيل في خدماتنا.</p>
          </li>
          <li id="Privacy_of_user_data" className={classes.term_item}>
            <h3>خصوصية بيانات المستخدم</h3>
            <p>لا يقوم ماكس مسنجر ببيع أو تأجير أو استثمار بياناتك الشخصية أو المحتوى بأي شكل من الأشكال. نعطي الأولوية لأمن معلوماتك ونستخدم التشفير من طرف إلى طرف لضمان حماية رسائلك ومحتوياتك. خصوصيتك هي أولويتنا القصوى.</p>
          </li>
          <li id="Contact_List" className={classes.term_item}>
            <h3>قائمة الإتصال </h3>
            <p>في فترات منتظمة ، يحتاج ماكس مسنجر إلى قراءة دفتر العناوين الخاص بك كما هو مخزّن على هاتفك لمعرفة من يستخدم ماكس مسنجر من أصدقائك الموجودين في جهات الاتصال الخاصة بك.</p>
          </li>
          <li id="Software" className={classes.term_item}>
            <h3>التحسينات البرمجية والحماية</h3>
            <p>لتمكين الميزات الجديدة والوظائف المحسنة والحماية البرمجية، فإنك توافق على تنزيل التحديثات وتثبيتها على تطبيق ماكس مسنجر.</p>
          </li>
          <li id="Fees_and_Taxes" className={classes.term_item}>
            <h3>الرسوم والضرائب</h3>
            <p>ماكس مسنجر هو تطبيق خدمات رسائل فورية ومكالمات صوتية وفديوية مجانية عبر الإنترنت ولا يفرض رسوما على استخدام اي من الخدمات اعلاه، ولكن قد يترتب على استخدامك للانترنت رسوم استهلاك البيانات تبعا لشركة الهاتف الجوال التي تستخدمها، أنت مسؤول عن البيانات ورسوم شركة الجوال والضرائب المرتبطة بالأجهزة التي تستخدم خدماتنا عليها.</p>
          </li>
          <li id="Account_Deletion_Option" className={classes.term_item}>
            <h3>خيار حذف الحساب </h3>
            <p>يمكن للمستخدمين حذف حساباتهم والبيانات المرتبطة بها عن طريق الوصول إلى تفاصيل الحساب ، واختيار "حذف الحساب" من القائمة الكاملة في قسم "إدارة الحساب". يؤدي هذا الإجراء إلى إنهاء الحساب بشكل دائم وإزالة جميع المعلومات المرتبطة به.</p>
          </li>
          <li id="Legal_and_Acceptable_Use" className={classes.term_item}>
            <h3>الاستخدام القانوني لماكس مسنجر </h3>
            <p>أنت توافق على استخدام ماكس مسنجر فقط للأغراض القانونية والمصرح بها والمقبولة. لن تستخدم (أو تساعد الآخرين في استخدام) هذا التطبيق بطرق: <ul style={{ listStyleType: "disc" }}><li> تنتهك أو تنتهك حقوق مستخدمينا أو غيرهم ، بما في ذلك الخصوصية أو الدعاية أو الملكية الفكرية أو حقوق الملكية الأخرى  </li><li> تتضمن إرسال اتصالات غير قانونية أو غير مسموح بها مثل الرسائل الجماعية المزعجة والرسائل التلقائية والطلب التلقائي باستخدام روبوتات الدردشة وما شابه ذلك.</li></ul></p>
          </li>
          <li id="Harm_to_Max_Messenger" className={classes.term_item}>
            <h3>الاضرار المتعمد بخدمات ماكس مسنجر </h3>
            <p>لا يجوز لك (أو مساعدة الآخرين على) الوصول إلى خدماتنا أو استخدامها أو تعديلها أو توزيعها أو نقلها أو استغلالها بطرق غير مصرح بها أو بطرق تضر بتطبيقاتنا أو خدماتنا أو أنظمتنا. على سبيل المثال ، يجب ألا <ul style={{ listStyleType: "disc" }}><li> تكتسب أو تحاول الحصول على وصول غير مصرح به إلى خدماتنا أو أنظمتنا  </li><li> تعطيل نزاهة خدماتنا أو أدائها  </li><li> إنشاء حسابات لخدماتنا من خلال وسائل غير مصرح بها أو آلية  </li><li> جمع معلومات حول مستخدمينا بأي طريقة غير مصرح بها او</li><li> بيع خدماتنا أو تأجيرها أو فرض رسوم عليها.</li></ul></p>
          </li>
          <li id="No_Access_to_Emergency_Services" className={classes.term_item}>
            <h3>الوصول الى خدمات الطوارئ </h3>
            <p>ماكس مسنجر هو تطبيق مراسلة فورية مجاني ولا يحل محل الهاتف المحمول او الثابت، لا يوفر ماكس مسنجر إمكانية الوصول إلى مقدمي خدمات الطوارئ مثل الشرطة أو الإطفاء أو المستشفيات أو منظمات السلامة العامة الأخرى. تأكد من أنه يمكنك الاتصال بمقدمي خدمة الطوارئ من خلال هاتف محمول أو خط ثابت أو خدمة أخرى.</p>
          </li>
          <li id="Third-party_services" className={classes.term_item}>
            <h3>خدمات الطرف الثالث </h3>
            <p>قد يسمح لك ماكس مسنجر بالوصول إلى مواقع الويب والتطبيقات والمحتوى والمنتجات والخدمات الأخرى التابعة لجهات خارجية أو استخدامها أو التفاعل معها. عندما تستخدم خدمات جهات خارجية ، فإن شروطها وسياسات الخصوصية الخاصة بها تحكم استخدامك لهذه الخدمات.</p>
          </li>
          <li id="Privacy_Policy_Updates" className={classes.term_item}>
            <h3>تحديثات سياسة الخصوصية </h3>
            <p>قد نقوم بتغيير سياسة الخصوصية الخاصة بنا من وقت لآخر لتعكس ممارساتنا وخدماتنا الحالية.  يمكنك مراجعة هذه الصفحة بانتظام لأية تعديلات.  باستخدام خدماتنا ، فإنك توافق على سياسة الخصوصية المحدثة الخاصة بنا.</p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  )
}

export default TermsAr
